import { useContext, useEffect, useRef, useState } from "react";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import Card from "../../../components/Card";
import PlayerButton from "../../../components/PlayerButton";
import CongratulationsModal from "../components/CongratulationsModal";
import Confetti from "react-confetti";
import Note from "../components/Note";
import NOTES from "../../../utils/constants/NOTES";
import GameDescriptionModal from "../../../components/GameDescriptionModal";

import RemoveButton from "../../../assets/icons/remove-button.svg";
import RECORD_PARTS from "../../../utils/constants/RECORD_PARTS";
import { useTranslate } from "../../../utils/hooks/useTranslate";
import API from "../../../utils/API";
import GameEndModal from "../components/GameEndModal";
import { DEMO_TIME_LIMIT } from "../Demo";
import { AppContext } from "../../../context/AppContext";
import endVideoCover from "../../../assets/img/end-video.png";
import AlignAgainModal from "../../Virtuoso/components/AlignAgainModal";

import "drag-drop-touch";
import isIOS from "../../../utils/helpers/isIOS";

const GameScreen = ({ endDemo, updateDemoState, demoState, demoUser }) => {
  const [notes, setNotes] = useState([]);
  const [onPlayerNotes, setOnPlayerNotes] = useState([]);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [isPlayingSong, setIsPlayingSong] = useState(false);
  const [isPausedSong, setIsPausedSong] = useState(false);
  const [, setFullTrackCheckingProgress] = useState(0);
  const [singlePlayingNote, setSinglePlayingNote] = useState(null);

  const [hasEmptyNote, setHasEmptyNote] = useState(false);
  const { app } = useContext(AppContext);

  // eslint-disable-next-line no-unused-vars
  const [_, setOnPlayerTrackCheckingProgress] = useState(0);
  const [currentCheckedTrackIndex, setCurrentCheckedTrackIndex] =
    useState(null);
  const [startFromNote, setStartFromNote] = useState(0);

  const [currentLevel, setCurrentLevel] = useState(0);

  const [roomState, setRoomState] = useState({
    trackParts: [],
    fullTrackUrl: "",
    artistImageUrl: "",
    artistName: "",
    trackName: "",
  });

  const [appState, setAppState] = useState({
    gameStart: new Date(),
    score: 0,
    modal: null,
  });

  const fullTrackAudioRef = useRef(null);
  const partTrackAudioRef = useRef(null);
  const singleTrackAudioRef = useRef(null);
  const endVideoCoverRef = useRef(null);

  const { updateApp } = useContext(AppContext);

  const { translate } = useTranslate();

  const [demoServicePath, setDemoServicePath] = useState("");

  useEffect(() => {
    startDemoGame();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [demoServicePath]);

  useEffect(() => {
    setDemoServicePath(`DemoGame?lang=${app.language}&screen=demo`);
  }, [app.language]);


  const startDemoGame = (callback = null, nextLevel = false) => {
    setIsPlaying(false);
    setIsPaused(false);
    setIsPlayingSong(false);
    setIsPausedSong(false);
    setOnPlayerNotes([]);
    setFullTrackCheckingProgress(0);
    setSinglePlayingNote(null);
    setOnPlayerTrackCheckingProgress(0);
    setCurrentCheckedTrackIndex(null);

    if (nextLevel) {
      const nextLevelData = demoState?.tracks[currentLevel + 1];

      if (nextLevelData) {
        setRoomState(nextLevelData);

        setAppState({
          ...appState,
          modal: null,
        });

        updateDemoState({
          currentLevel: currentLevel + 1,
        });
        setCurrentLevel(currentLevel + 1);

        if (callback) callback();
      }
    } else {
      if(!demoServicePath) return;
      API.getData(demoServicePath).then(({ data: res }) => {
        const { demoVirtuosoScreenData, mioMessages } = res.data;

        const {
          trackParts,
          fullTrackUrl,
          artistImageUrl,
          artistName,
          trackName,
        } = demoVirtuosoScreenData[currentLevel];

        updateApp({ mio: mioMessages });

        setRoomState({
          trackParts: trackParts?.sort((a, b) => a.order - b.order),
          fullTrackUrl,
          artistImageUrl,
          artistName,
          trackName,
        });

        const tracks = demoVirtuosoScreenData;

        const totalPoints = tracks.reduce((acc, track) => {
          return acc + track.point;
        }, 0);

        updateDemoState({
          tracks: tracks.map(
            ({
              artistImageUrl,
              artistName,
              trackName,
              trackParts,
              fullTrackUrl,
              point,
            }) => ({
              trackParts: trackParts?.sort((a, b) => a.order - b.order),
              fullTrackUrl,
              artistImageUrl,
              artistName,
              trackName,
              point: point || 0,
            })
          ),
          currentLevel,
          totalPoints,
        });

        setAppState({
          ...appState,
          gameStart: new Date(),
          modal: null,
        });

        if (callback) callback();
      });
    }
  };

  useEffect(() => {
    if (!roomState.trackParts?.length) return;

    const notes = roomState.trackParts?.map((record) => {
      return {
        ...record,
        onPlayer: false,
      };
    });

    setNotes(notes);
    setOnPlayerNotes(Array.from({ length: notes.length }, () => false));
  }, [roomState.trackParts]);

  useEffect(() => {
    if (onPlayerNotes.filter((note) => note).length === 0) return;

    setHasEmptyNote(false);

    if (!onPlayerNotes[0]) setHasEmptyNote(1);

    let lastIndex = -1;
    onPlayerNotes.some((note, index) => {
      if (note && index !== lastIndex + 1) {
        setHasEmptyNote(index);
        return true;
      }

      if (note) lastIndex = index;
      return false;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onPlayerNotes]);

  useEffect(() => {
    let interval = "";
    if (isPlayingSong) {
      if (isPausedSong) clearInterval(interval);

      interval = setInterval(() => {
        setFullTrackCheckingProgress(
          (fullTrackCheckingProgress) => fullTrackCheckingProgress + 0.1
        );
      }, 100);
    }

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPlayingSong, isPausedSong]);

  useEffect(() => {
    let interval = "";

    if (isPlaying && !isPaused) {
      interval = setInterval(() => {
        setOnPlayerTrackCheckingProgress(
          (onPlayerTrackCheckingProgress) => onPlayerTrackCheckingProgress + 0.1
        );
      }, 100);
    } else {
      clearInterval(interval);
    }

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPlaying, isPaused]);

  const handleDragStart = (e, note) => {
    if (isPlaying || note.onPlayer) return;

    e.dataTransfer.setData("note", JSON.stringify(note));
  };

  const handleDragOver = (e) => {
    e.preventDefault();

    const el = e.target;
    const { classList } = el;
    if (classList.contains("pitch") && !classList.contains("drag-area")) {
      el.classList.add("drag-area");
    }
  };

  const handleDrop = (e) => {
    e.target.classList.remove("drag-area");

    if (isPlaying && !isPaused) return;

    if (isPlaying) {
      handleEndCheckingTracks();
    }

    const { index } = e.target.dataset;

    let note = e.dataTransfer.getData("note");

    if (!note) return;

    note = JSON.parse(note);

    addNoteToPlayer(note, index);
  };

  const addNoteToPlayer = (note, index) => {
    if (isPlaying && !isPaused) return;

    if (isPlaying) {
      handleEndCheckingTracks();
    }

    const newOnPlayerNotes = [...onPlayerNotes];

    newOnPlayerNotes[index] = note;

    const newNotes = [...notes];

    const noteIndex = newNotes.findIndex((n) => n?.partId === note?.partId);
    newNotes[noteIndex].onPlayer = true;

    if (onPlayerNotes[index]) {
      const oldNoteIndex = newNotes.findIndex(
        (n) => n?.partId === onPlayerNotes[index]?.partId
      );

      newNotes[oldNoteIndex].onPlayer = false;
    }

    setOnPlayerNotes(newOnPlayerNotes);
    setNotes(newNotes);
  };

  const removeNoteFromPlayer = (index) => {
    if (isPlaying && !isPaused) return;

    if (isPlaying) {
      handleEndCheckingTracks();
    }

    let newOnPlayerNotes = [...onPlayerNotes];

    newOnPlayerNotes[index] = false;

    setOnPlayerNotes(newOnPlayerNotes);

    const newNotes = [...notes];

    const noteIndex = newNotes.findIndex(
      (n) => n?.partId === onPlayerNotes[index]?.partId
    );
    newNotes[noteIndex].onPlayer = false;

    setNotes(newNotes);
  };

  const handleCheckTracks = (playFromNote = 0, checkOrder = false) => {
    if (
      (isPlaying && !isPaused) ||
      (isPlayingSong && !isPausedSong) ||
      onPlayerNotes.filter((note) => note)?.length < 1 ||
      singlePlayingNote
    )
      return;

    if (
      onPlayerNotes.filter((note) => note)?.length ===
        roomState.trackParts?.length &&
      checkOrder
    ) {
      handleEndFullTrack();
      checkTrackOrder(onPlayerNotes?.map((n) => n.partId));
    } else {
      if (onPlayerNotes[playFromNote]?.partId === null) return;

      const notesFromMiddle = onPlayerNotes?.slice(playFromNote);

      const emptyIndex = notesFromMiddle.findIndex((note) => !note);

      const tracks = playFromNote
        ? notesFromMiddle?.slice(0, emptyIndex > -1 ? emptyIndex : undefined)
        : onPlayerNotes?.slice(
            playFromNote || 0,
            hasEmptyNote
              ? hasEmptyNote
              : onPlayerNotes?.filter((note) => note)?.length
          );

      setStartFromNote(playFromNote);

      handlePlayAllTracks(
        tracks?.map((note) => note?.recordUrl)?.filter((note) => note),
        playFromNote
      ).then((data) => {
        postHandlePlayAllTracks(data, playFromNote);
      });
    }
  };

  const postHandlePlayAllTracks = () => {
    setIsPlaying(true);
    setIsPaused(false);
  };

  const handlePausePlayCheckingTracks = () => {
    handlePausePlayAllTracks(!isPaused);

    setIsPaused(!isPaused);
  };

  const handleEndCheckingTracks = () => {
    setIsPlaying(false);
    setIsPaused(false);

    setOnPlayerTrackCheckingProgress(0);
    setFullTrackCheckingProgress(0);

    const pitchArea = document.querySelector(".pitch-area");

    if (pitchArea) {
      pitchArea.classList.add("ended");

      setTimeout(() => {
        setCurrentCheckedTrackIndex(null);
      }, 210);

      setTimeout(() => {
        pitchArea.classList.remove("ended");
      }, 300);
    } else {
      setCurrentCheckedTrackIndex(null);
    }
  };

  const handlePlayFullTrack = (gameOver = false) => {
    if (
      (isPlaying && !isPaused) ||
      (isPlayingSong && !isPausedSong) ||
      singlePlayingNote
    )
      return;

    handlePlayTrackPart(
      roomState.fullTrackUrl,
      handleEndFullTrack,
      gameOver
    ).then(() => {
      setIsPlayingSong(true);
    });
  };

  const handlePauseFullTrack = () => {
    if (isPlayingSong) {
      handlePausePlayTrack().then((state) => {
        if (state === "paused") setIsPausedSong(true);
        else setIsPausedSong(false);
      });
    }
  };

  const handleEndFullTrack = () => {
    setIsPlayingSong(false);
    setIsPausedSong(false);

    setFullTrackCheckingProgress(0);
    fullTrackAudioRef.current.currentTime = 0;
  };

  const handleSeekTrack = (e) => {
    if (e.target.classList.contains("overlay")) {
      const barEl = document.querySelector(".progress .progress-bar");
      const ballEl = document.querySelector(".progress .progress-ball");

      barEl.style.transition = `width 200ms ease`;
      ballEl.style.transition = `left 200ms ease`;

      const progress = e.target.getBoundingClientRect();
      const progressWidth = progress.width;
      const progressX = e.clientX - progress.x;
      const progressPercent = (progressX / progressWidth) * 100;
      const progressTime =
        (progressPercent * fullTrackAudioRef?.current?.duration) / 100;
      setFullTrackCheckingProgress(progressTime < 0 ? 0 : progressTime);

      if (isPlayingSong) {
        handleSeekTrackPart(progressTime < 0 ? 0 : progressTime);
      }

      setTimeout(() => {
        barEl.style.removeProperty("transition");
        ballEl.style.removeProperty("transition");
      }, 210);
    }
  };

  const checkTrackOrder = (tracks) => {
    const correctOrder = roomState.trackParts
      ?.sort((a, b) => a.originalOrder - b.originalOrder)
      .map((record) => record.partId);

    if (JSON.stringify(tracks) === JSON.stringify(correctOrder)) {
      const totalSecond = Math.floor(
        (new Date().getTime() - new Date(appState?.gameStart).getTime()) / 1000
      );

      API.getData(
        `DemoGame/calculatePoint?totalSecond=${totalSecond}&slug=${
          currentLevel === 0 ? "easy" : "easy"
        }`
      ).then(({ data: res }) => {
        setIsPlaying(false);
        setIsPlayingSong(false);
        setAppState({
          ...appState,
          score: appState.score
            ? parseInt(appState.score) + parseInt(res)
            : res,
          modal: {
            modalType: "TrackSuccess",
            data: {
              trackPoint: res,
              gamePoint: appState.score
                ? parseInt(appState.score) + parseInt(res)
                : res,
            },
          },
        });

        const newDemoState = { ...demoState };

        if (newDemoState?.tracks[currentLevel]) {
          newDemoState.tracks[currentLevel].point = parseInt(res);
        }

        newDemoState.totalPoints = newDemoState.totalPoints
          ? parseInt(newDemoState.totalPoints) + parseInt(res)
          : res;

        updateDemoState(newDemoState);
      });
    } else {
      let correctCount = 0;
      let wrongCount = 0;

      tracks.forEach((track, index) => {
        if (track === correctOrder[index]) {
          correctCount++;
        } else {
          wrongCount++;
        }
      });

      setAppState({
        ...appState,
        modal: {
          modalType: "TrackPartCheck",
          data: {
            correctCount,
            wrongCount,
          },
        },
      });
    }
  };

  const handlePlayAllTracks = async (tracks, playFromNote) => {
    let trackIndex = 0;
    if (onPlayerNotes[trackIndex + playFromNote]) {
      setCurrentCheckedTrackIndex(trackIndex + playFromNote);
      partTrackAudioRef.current.src = tracks[trackIndex];
      partTrackAudioRef?.current?.play();

      partTrackAudioRef.current.onended = () => {
        trackIndex++;
        if (trackIndex < tracks.length) {
          setCurrentCheckedTrackIndex(trackIndex + playFromNote);
          partTrackAudioRef.current.src = tracks[trackIndex];
          partTrackAudioRef.current.play();
        } else {
          handleEndCheckingTracks();
        }
      };

      return {
        duration: partTrackAudioRef.current.duration,
      };
    } else {
      handleEndCheckingTracks();
    }
  };

  const handlePausePlayAllTracks = (pause) => {
    if (pause) {
      partTrackAudioRef.current.pause();
      setIsPaused(true);
    } else {
      partTrackAudioRef.current.play();
      setIsPaused(false);
    }
  };

  const handlePlayTrackPart = async (part, callback, isGameOver = false) => {
    fullTrackAudioRef?.current?.load();
    fullTrackAudioRef.current.src = part;
    if (isGameOver) fullTrackAudioRef.current.volume = 0.5;
    else fullTrackAudioRef.current.volume = 1;

    fullTrackAudioRef.current.play();
    fullTrackAudioRef.current.onended = callback;
    fullTrackAudioRef.current.onpause = () => {
      setIsPausedSong(true);
    };
    fullTrackAudioRef.current.onplay = () => {
      setIsPlayingSong(true);
      setIsPausedSong(false);
    };
  };

  const handlePausePlayTrack = async () => {
    if (fullTrackAudioRef?.current?.paused) {
      fullTrackAudioRef.current.play();
      setIsPausedSong(false);
    } else {
      fullTrackAudioRef.current.pause();
      setIsPausedSong(true);
    }
  };

  const handleSeekTrackPart = (time) => {
    fullTrackAudioRef.current.currentTime = time;
  };

  const closeModal = () => {
    setAppState({ ...appState, modal: null });
  };

  const handlePlaySingleTrack = (note) => {
    if (
      (isPlayingSong && !isPausedSong) ||
      (isPlaying && !isPaused) ||
      singlePlayingNote ||
      !note
    ) {
      singleTrackAudioRef.current.pause();
      setSinglePlayingNote(null);
      if (singlePlayingNote === note?.partId) {
        return;
      }
    }

    singleTrackAudioRef.current.src = note?.recordUrl;
    singleTrackAudioRef.current.play();
    singleTrackAudioRef.current.onended = () => {
      setSinglePlayingNote(null);
    };

    singleTrackAudioRef.current.onplay = () => {
      setSinglePlayingNote(note?.partId);
    };
  };

  const fadeOut = (callback) => {
    if (isIOS()) {
      fullTrackAudioRef.current.pause();
      fullTrackAudioRef.current.currentTime = 0;
      callback();
      return;
    }

    if (
      !fullTrackAudioRef.current ||
      !fullTrackAudioRef.current.volume ||
      fullTrackAudioRef.current.volume === 1
    ) {
      fullTrackAudioRef.current.pause();
      fullTrackAudioRef.current.currentTime = 0;
      callback();
      return;
    }
    if (fullTrackAudioRef.current.volume > 0.03) {
      fullTrackAudioRef.current.volume -= 0.03;
      setTimeout(() => fadeOut(callback), 15);
    } else {
      fullTrackAudioRef.current.pause();
      fullTrackAudioRef.current.currentTime = 0;
      callback();
    }
  };

  const handleEndDemo = () => {
    fadeOut(() => {
      endVideoCoverRef.current.classList.remove("over");

      setTimeout(() => {
        endDemo();
      }, 1000);
    });
  };

  const handleContinueDemo = (callback) => {
    fadeOut(() =>
      currentLevel === 1 ? handleEndDemo() : startDemoGame(callback, true)
    );
  };

  const handleDemoOver = () => {
    setAppState({ ...appState, modal: { modalType: "DemoEnd" } });
  };

  return (
    <div
      className="virtuoso container-fluid main d-flex flex-column align-items-center justify-content-between demo-fix demo"
      style={{
        backgroundImage: `url(${RECORD_PARTS[0]?.box})`,
      }}
    >
      <Header onlyTitle title="Musing Demo" />
      <div className="row d-flex align-items-center justify-content-center">
        <div className="col-12 col-md-9">
          <Card className="d-flex flex-column align-items-center overflow-auto">
            <div className="notes-holder d-flex flex-row justify-content-center align-items-center">
              {notes
                ?.sort((a, b) => a.order - b.order)
                .map((note, index) => (
                  <Note
                    key={index}
                    note={note}
                    isAdded={note?.onPlayer}
                    onClick={() => {
                      handlePlaySingleTrack(note);
                    }}
                    draggable={!note?.onPlayer}
                    onDragStart={(e) =>
                      !note?.onPlayer
                        ? handleDragStart(e, note)
                        : e.preventDefault()
                    }
                    onPlayClick={handlePlaySingleTrack}
                    isPlaying={singlePlayingNote === note?.partId}
                  />
                ))}
            </div>
            <div className="pitch-area position-relative player-bg d-flex flex-row align-items-center justify-content-center my-3">
              {onPlayerNotes.map((playerNote, i) => (
                <div
                  className={`pitch d-flex flex-column align-items-center justify-content-between cursor-pointer position-relative py-3 ${
                    playerNote?.partId !== null ? "pitch-blue" : ""
                  }`}
                  onDragOver={(e) => handleDragOver(e)}
                  onDragLeave={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    e.target.classList.remove("drag-area");
                  }}
                  onDrop={(e) => handleDrop(e)}
                  key={i}
                  data-index={i}
                  onClick={() => {
                    if (playerNote) {
                      handleCheckTracks(i);
                    }
                  }}
                >
                  <div
                    className="single-track-progress-bar position-absolute w-100 h-100"
                    data-progress={
                      !playerNote?.partId || i < startFromNote
                        ? 0
                        : currentCheckedTrackIndex > i
                        ? 100
                        : currentCheckedTrackIndex === i
                        ? Math.floor(
                            (partTrackAudioRef?.current?.currentTime /
                              partTrackAudioRef?.current?.duration) *
                              100
                          )
                        : 0
                    }
                  ></div>
                  {playerNote?.iconId && (
                    <img
                      src={NOTES[playerNote?.iconId]}
                      alt="note"
                      className="note-icon flex-shrink-0"
                    />
                  )}
                  {playerNote && (
                    <div
                      className="remove-button d-flex align-items-center justify-content-center"
                      role="button"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        removeNoteFromPlayer(i);
                      }}
                    >
                      <img src={RemoveButton} alt="Remove icon" />
                    </div>
                  )}
                </div>
              ))}
            </div>
            <div className="col-10 d-flex align-items-center my-2 my-md-3">
              <div className="play-song-button d-flex flex-shrink-0 align-items-center justify-content-center text-white">
                <PlayerButton
                  isPlaying={isPlayingSong && !isPausedSong}
                  onClick={
                    isPlayingSong
                      ? handlePauseFullTrack
                      : () => handlePlayFullTrack(false)
                  }
                  customText={
                    isPlayingSong
                      ? isPausedSong
                        ? translate(`CONTINUE`)
                        : translate(`PAUSE`)
                      : translate(`LISTEN_MUSIC`)
                  }
                  disabled={(isPlaying && !isPaused) || singlePlayingNote}
                />
              </div>
              <div className="progress mx-3 position-relative">
                <div
                  className="overlay position-absolute w-100 h-100"
                  role="button"
                  onClick={handleSeekTrack}
                ></div>
                <div
                  className="progress-bar col-12"
                  role="progressbar"
                  aria-valuenow={Math.floor(
                    fullTrackAudioRef?.current?.currentTime
                  )}
                  aria-valuemin="0"
                  aria-valuemax="100"
                  data-progress={
                    fullTrackAudioRef?.current &&
                    fullTrackAudioRef?.current?.currentTime
                      ? Math.floor(
                          (fullTrackAudioRef?.current?.currentTime /
                            fullTrackAudioRef?.current?.duration) *
                            100
                        )
                      : 0
                  }
                ></div>
                <div
                  className="progress-ball position-absolute"
                  role="button"
                  style={{
                    left: `${
                      fullTrackAudioRef?.current && isPlayingSong
                        ? Math.floor(
                            (fullTrackAudioRef?.current?.currentTime /
                              fullTrackAudioRef?.current?.duration) *
                              100
                          )
                        : 0
                    }%`,
                  }}
                ></div>
              </div>
              {fullTrackAudioRef?.current && isPlayingSong && (
                <p className="progress-text text-white mb-0">
                  00:
                  {fullTrackAudioRef?.current?.currentTime < 10
                    ? "0" + Math.floor(fullTrackAudioRef?.current?.currentTime)
                    : Math.floor(fullTrackAudioRef?.current?.currentTime)}
                  /00:{Math.floor(fullTrackAudioRef?.current?.duration)}
                </p>
              )}
            </div>
            <div className="play-button-area d-flex flex-column align-items-center justify-content-center mt-3 mt-md-0 py-2">
              <PlayerButton
                customText={
                  isPlaying
                    ? isPaused
                      ? translate(`CONTINUE`)
                      : translate(`PAUSE`)
                    : onPlayerNotes.filter((note) => note).length ===
                      notes.length
                    ? translate(`CHECK_PART_ORDER`)
                    : translate(`LISTEN_PARTS`)
                }
                disabled={
                  onPlayerNotes.filter((note) => note).length < 1 ||
                  (isPlayingSong && !isPausedSong) ||
                  singlePlayingNote
                }
                onClick={() =>
                  isPlaying
                    ? handlePausePlayCheckingTracks()
                    : handleCheckTracks(
                        onPlayerNotes?.findIndex((note) => note),
                        true
                      )
                }
              />
            </div>
          </Card>
        </div>
      </div>

      <Footer
        showTimer
        customCountdown={
          new Date(appState?.gameStart).getTime() + DEMO_TIME_LIMIT * 1000
        }
        demoFooter
        onCountdownComplete={handleDemoOver}
        demoUserInfo={demoUser}
      />

      {appState.modal?.modalType === "TrackPartCheck" && (
        <AlignAgainModal
          show={appState.modal?.modalType === "TrackPartCheck"}
          onClose={closeModal}
          corrects={appState.modal?.data?.correctCount}
          wrongs={appState.modal?.data?.wrongCount}
        />
      )}
      <GameDescriptionModal type="VIRTUOSO" />

      <CongratulationsModal
        show={appState.modal?.modalType === "TrackSuccess"}
        roundPoint={appState.modal?.data?.trackPoint}
        gamePoint={appState.modal?.data?.gamePoint}
        artistName={roomState?.artistName}
        trackName={roomState?.trackName}
        cover={roomState?.artistImageUrl}
        handlePlaySong={() => handlePlayFullTrack(true)}
        isSongPlaying={isPlayingSong}
        continueDemo={handleContinueDemo}
        demoUser={demoUser}
      />

      <GameEndModal
        show={appState.modal?.modalType === "DemoEnd"}
        gamePoint={appState?.score}
        artistName={roomState?.artistName}
        trackName={roomState?.trackName}
        cover={roomState?.artistImageUrl}
        endDemo={handleEndDemo}
        demoUser={demoUser}
      />

      {appState.modal?.modalType === "TrackSuccess" && (
        <Confetti
          width={window.innerWidth}
          height={window.innerHeight}
          numberOfPieces={200}
          recycle={appState.modal?.modalType === "TrackSuccess"}
          gravity={0.1}
        />
      )}

      <img
        src={endVideoCover}
        className="position-absolute vw-100 vh-100 end-video-cover over"
        alt="End video cover"
        ref={endVideoCoverRef}
      />

      <audio ref={fullTrackAudioRef}></audio>
      <audio ref={partTrackAudioRef}></audio>
      <audio ref={singleTrackAudioRef}></audio>
    </div>
  );
};

export default GameScreen;
